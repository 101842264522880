<template>
  <div>
		<section class="error-page section">
			<div class="container">
				<div class="row">
					<div class="col-lg-6 offset-lg-3 col-12">
						<div class="error-inner">
							<h1>404<span>Oop's  sorry kami tidak menemukan yang anda cari</span></h1>
						</div>
					</div>
				</div>
			</div>
		</section>	
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>